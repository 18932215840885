


















import { Component, Vue } from 'vue-property-decorator';
@Component({
    name: 'AppMain'
})
export default class extends Vue {
    // get cachedViews() {
    //     return TagsViewModule.cachedViews;
    // }

    get key() {
        return this.$route.path;
    }
}
