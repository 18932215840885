























import { Component, Vue } from 'vue-property-decorator';

import YwhzLangSelectCard from '@/components/PortalSite/LangSelectCard/index.vue';

import { UserModule } from '@/store/modules/user';

import { appConfigModule } from '@/store/modules/appConfig';

@Component({
    name: 'TopBar',
    components: {
        YwhzLangSelectCard
    }
})
export default class TopBar extends Vue {
    public get userData() {
        return UserModule.userData;
    }

    public get isShow() {
        // eslint-disable-next-line no-unneeded-ternary
        const is = this.userData.Token ? true : false;
        return is;
    }

    public jump(path) {
        this.$router.push({
            path
        });
    }

    public open() {
        window.location.href = (this.appConfig.vueAdmin + '/admin');
    }

    public get appConfig() {
        return appConfigModule.appConfig;
    }
}
