import request from '@/utils/request';
import { appConfigModule } from '@/store/modules/appConfig';

// 获取运费试算
export const GetCalculateTrial = (params?: any) => {
    return request({
        url: `${appConfigModule.appConfig.vueHkApi}/CMS/Page/ShopPrice/GetCalculateTrial`,
        method: 'GET',
        params
    });
};

// 新的详情报价表
export const GetPriceSheet = (params?: any) => {
    return request({
        url: `${appConfigModule.appConfig.vueHkApi}/CMS/Page/ShopPrice/GetPriceSheetV3`,
        method: 'GET',
        params
    });
};

// 货态查询接口
export const CarrierDeliveryTrack = (params?: any) => {
    return request({
        url: `${appConfigModule.appConfig.vueHkApi}/CMS/Page/CarrierDeliveryTrack/GetTrack`,
        method: 'GET',
        params
    });
};

// 获取脚本接口
export const EmbeddedScriptList = (params?: any) => {
    return request({
        url: `${appConfigModule.appConfig.vueHkApi}/CMS/Page/EmbeddedScript/GetList`,
        method: 'GET',
        params
    });
};

// 文章列表
export const ArticleGetList = (params?: any) => {
    return request({
        url: `${appConfigModule.appConfig.vueHkApi}/CMS/Page/Article/GetList`,
        method: 'GET',
        params
    });
};

/*
根据Id获取内容信息
GET /Article/{id}
接口ID：20210174
接口地址：https://www.apifox.cn/web/project/987243/apis/api-20210174
*/
export const ArticleId = (id?: any) => {
    return request({
        url: `${appConfigModule.appConfig.vueHkApi}/CMS/Page/Article/${id}`,
        method: 'GET'
    });
};

// 登录
export const login = (params: any, config) =>
    request({
        url: '/Shop/Login',
        method: 'GET',
        params,
        ...config
    });

// 註冊
export const Registered = (data: any) =>
    request({
        url: '/Shop/Registered',
        method: 'POST',
        data
    });

// 改密码
export const Forget = (data: any) =>
    request({
        url: '/Shop/Forget',
        method: 'POST',
        data
    });

// 退出
export const logout = (params?: any) =>
    request({
        url: '/Shop/LogOut',
        method: 'GET',
        params
    });

// 获取图片验证码
export const GetVerifyCode = (config?) =>
    request({
        url: '/Shop/GetVerifyCode',
        method: 'GET',
        ...config
    });

/*
获取手机短信验证码(带图形验证码校验)
  GET /GetPhoneVerifyCodeV2
  接口ID：183969501
  接口地址：https://app.apifox.com/link/project/2467265/apis/api-183969501
*/
export const GetPhoneVerifyCodeV2 = (params: any, config: any) => {
    return request({
        url: '/Shop/GetPhoneVerifyCodeV2',
        method: 'GET',
        params,
        ...config
    });
};

/*
获取列表
GET /youxuan/Product/GetList
接口ID：50212956
接口地址：https://www.apifox.cn/web/project/1950931/apis/api-50212956
*/
export const ProductGetList = (params?: any) =>
    request({
        url: `${appConfigModule.appConfig.vueHkApi}/youxuan/Product/GetList`,
        method: 'GET',
        params
    });

// 获取店铺配置信息
export const ShopConfig = (params?: any) =>
    request({
        url: `${appConfigModule.appConfig.vueHkApi}/CMS/Page/Shop`,
        method: 'GET',
        params
    });
