












import { Component, Vue } from 'vue-property-decorator';
import { otherModule } from '@/store/modules/other';
@Component({
    name: 'LangSelectCard',
    components: {}
})
export default class LangSelectCard extends Vue {
    public get language() {
        return otherModule.language;
    }

    public async handleSetLanguage(lang) {
        await location.reload();
        this.$i18n.locale = lang;
        otherModule.setLanguage(lang);
    }
}
