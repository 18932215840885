



























import { Component, Vue } from 'vue-property-decorator';
import AppMainVue from '@/layout/AppMain.vue';
import FooterMain from '@/layout/components/footer.vue';
import TopBar from '@/layout/components/top-bar.vue';
import MenuMain from '@/layout/components/menu-main.vue';
import MainLogo from '@/layout/components/logo.vue';

import SlexdSide from '@/layout/components/flexd-side.vue';

import { ShopConfig, EmbeddedScriptList } from '@/api/api';
import { otherModule } from '@/store/modules/other';
@Component({
    name: 'Layout',
    components: {
        FooterMain,
        AppMainVue,
        TopBar,
        MainLogo,
        MenuMain,
        SlexdSide
    }
})
export default class Layout extends Vue {
    public is: boolean = false;

    public type: string = 'footer';

    private created() {
        this.init();
        if (process.env.NODE_ENV === 'development' && (window.location.host.indexOf('test') !== -1 || window.location.host.indexOf('192.168') !== -1)) {
            this.is = true;
        }
    }

    private mounted() {
        this.initScript();
    }

    private async init() {
        try {
            const { Data } = await ShopConfig();
            otherModule.setShopConfig(Data);
        } catch (error) {
            console.warn(error);
        }
    }

    private parseDom(arg: string) {
        const objE = document.createElement('div');
        objE.innerHTML = arg;
        return objE;
    }

    //  获取脚本类型
    private async initScript() {
        try {
            const params: any = [{ FieldName: 'ScriptTypeId', FieldValue: 2, ConditionalType: 0 }];
            const { DataList } = await EmbeddedScriptList({
                Expressionable: JSON.stringify(params)
            });

            DataList.forEach(item => {
                if (item.HtmlBody) {
                    const html = this.parseDom(item.HtmlBody);
                    document.body.appendChild(html);
                }
                if (item.ScriptBody) {
                    const script = document.createElement('script');
                    script.async = true;
                    script.innerText = item.ScriptBody;
                    document.head.appendChild(script);
                }
            });
        } catch (error) {
            console.warn(error);
        }
    }

    public get className() {
        const route = this.$route;
        if (route.name === 'Home') {
            return 'home';
        }
        return '';
    }

    // 鼠标移入
    public mouseenter() {
        if (this.className === 'home') {
            this.type = 'menu';
        }
    }

    public mouseleave() {
        if (this.className === 'home') {
            this.type = '';
        }
    }
}
