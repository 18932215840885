import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import Layout from '@/layout/index.vue';

Vue.use(VueRouter);

export const constantRoutes: RouteConfig[] = [
    {
        path: '/third-self-promotion',
        component: () => import(/* webpackChunkName: "transport" */ '@/views/transport/third-self-promotion.vue'),
        name: 'ThirdSelfPromotion',
        meta: {
            title: '自提點'
        }
    },
    {
        path: '/',
        component: Layout,
        redirect: '/Home',
        children: [
            {
                path: 'home',
                component: () => import(/* webpackChunkName: "home" */ '@/views/home/index.vue'),
                name: 'Home',
                meta: {
                    title: '首頁'
                }
            }
        ]
    },
    {
        path: '/charge',
        component: Layout,
        redirect: '/standard',
        children: [
            {
                path: 'standard',
                component: () => import(/* webpackChunkName: "charge" */ '@/views/charge-standard/index.vue'),
                name: 'Standard',
                meta: {
                    title: '收費標準',
                    noCache: false
                }
            },
            {
                path: 'standard-order-query',
                component: () => import(/* webpackChunkName: "charge" */ '@/views/charge-standard/order-query.vue'),
                name: 'StandardOrderQuery',
                meta: {
                    title: '快遞單號查詢'
                }
            }
        ]
    },
    {
        path: '/activity',
        component: Layout,
        redirect: '/activity-index',
        children: [
            {
                path: 'activity-index',
                component: () => import(/* webpackChunkName: "activity" */ '@/views/activity/index.vue'),
                name: 'ActivityIndex',
                meta: {
                    title: '優惠活動'
                }
            }
        ]
    },
    {
        path: '/transport',
        component: Layout,
        redirect: '/Introduce',
        children: [
            {
                path: 'introduce',
                component: () => import(/* webpackChunkName: "transport" */ '@/views/transport/introduce.vue'),
                name: 'Introduce',
                meta: {
                    title: '集運介紹'
                }
            },
            {
                path: 'teaching',
                component: () => import(/* webpackChunkName: "transport" */ '@/views/transport/teaching.vue'),
                name: 'Teaching',
                meta: {
                    title: '集運教學'
                }
            },
            {
                path: 'route',
                component: () => import(/* webpackChunkName: "transport" */ '@/views/transport/route.vue'),
                name: 'Route',
                meta: {
                    title: '集運線路'
                }
            },
            {
                path: 'self-Promotion',
                component: () => import(/* webpackChunkName: "transport" */ '@/views/transport/self-promotion.vue'),
                name: 'SelfPromotion',
                meta: {
                    title: '自提點'
                }
            },
            {
                path: 'terms',
                component: () => import(/* webpackChunkName: "transport" */ '@/views/transport/terms.vue'),
                name: 'Terms',
                meta: {
                    title: '集運條款'
                }
            }
        ]
    },
    {
        path: '/commodity',
        component: Layout,
        redirect: '/commodity-index',
        children: [
            {
                path: 'commodity-index',
                component: () => import(/* webpackChunkName: "commodity" */ '@/views/commodity/index.vue'),
                name: 'CommodityIndex',
                meta: {
                    title: '特價商品'
                }
            }
        ]
    },
    {
        path: '/about',
        component: Layout,
        redirect: '/enterprise-overview',
        children: [
            {
                path: 'enterprise-overview',
                component: () => import(/* webpackChunkName: "about" */ '@/views/about/enterprise-overview.vue'),
                name: 'EnterpriseOverview',
                meta: {
                    title: '企業概述'
                }
            },
            {
                path: 'business-consulting',
                component: () => import(/* webpackChunkName: "about" */ '@/views/about/business-consulting.vue'),
                name: 'BusinessConsulting',
                meta: {
                    title: '企業資訊'
                }
            },
            {
                path: 'business-consulting-details',
                component: () => import(/* webpackChunkName: "about" */ '@/views/about/business-consulting-details.vue'),
                name: 'BusinessConsultingDetails',
                meta: {
                    title: '資訊详情'
                }
            },
            {
                path: 'enterprise-style',
                component: () => import(/* webpackChunkName: "about" */ '@/views/about/enterprise-style.vue'),
                name: 'EnterpriseStyle',
                meta: {
                    title: '企業風采'
                }
            },
            {
                path: 'business-cooperation',
                component: () => import(/* webpackChunkName: "about" */ '@/views/about/business-cooperation.vue'),
                name: 'BusinessCooperation',
                meta: {
                    title: '商務合作'
                }
            },
            {
                path: 'return-goods',
                component: () => import(/* webpackChunkName: "about" */ '@/views/about/return-goods.vue'),
                name: 'ReturnGoods',
                meta: {
                    title: '關於退貨'
                }
            }
        ]
    },
    {
        path: '/agreement',
        component: Layout,
        children: [
            {
                path: 'agreement/:type',
                component: () => import(/* webpackChunkName: "agreement" */ '@/views/agreement/type.vue'),
                name: 'agreementType',
                meta: {
                    title: '协议'
                }
            },
            {
                path: 'missing-agreement',
                component: () => import(/* webpackChunkName: "agreement" */ '@/views/agreement/missing-agreement.vue'),
                name: 'MissingAgreement',
                meta: {
                    title: '协议'
                }
            }
        ]
    },
    {
        path: '/login/:type',
        component: () => import(/* webpackChunkName: "login" */ '@/views/login/index.vue'),
        name: 'login',
        meta: {
            title: '登录'
        }
    },
    {
        path: '/test',
        component: () => import(/* webpackChunkName: "test" */ '@/views/test/index.vue')
    },
    {
        path: '/error',
        component: () => import(/* webpackChunkName: "errorPage" */ '@/views/error-page/error.vue')
    },
    { // 注意这里必须放在最后，不然会有异步问题
        path: '*',
        redirect: '/error'
    }
];

const createRouter = () => new VueRouter({
    mode: 'history',
    base: '/',
    scrollBehavior: (to, from, savedPosition) => {
        if (savedPosition) {
            return savedPosition;
        } else {
            return { x: 0, y: 0 };
        }
    },
    routes: constantRoutes
});

const router = createRouter();

export function resetRouter() {
    const newRouter = createRouter();
    (router as any).matcher = (newRouter as any).matcher; // reset router
}

export default router;
