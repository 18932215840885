export enum searchConditionalTypeEnum {
    '等于' = 0,
    '模糊查询' = 1,
    '大于' = 2,
    '大于等于' = 3,
    '小于' = 4,
    '小于等于' = 5
}

// 店铺对应的 枚举
export enum shopEnum {
    '速风达' = '2',
    '台集运' = '3',
    '海快' = '4',
    '速风达集运' = '5',
    '派将' = '7',
    '泽宝' = '10'
}
