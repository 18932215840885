














































import { Component, Vue } from 'vue-property-decorator';
import { ArticleGetList } from '@/api/api';
import { searchConditionalTypeEnum } from '@/utils/type-enum';
@Component({
    name: 'FlexdSide',
    components: {}
})
export default class Login extends Vue {
    public codeSrc: any = '';
    public active: string = '';
    public isDiv: boolean = true;
    public src: string = require('@/assets/xiaoji.png');

    public list: any = [];

    private mounted() {
        this.init();
    }

    // 返回顶部
    public topHeader() {
        const timer = setInterval(function() {
            const osTop = document.documentElement.scrollTop || document.body.scrollTop;
            const ispeed = Math.floor(-osTop / 5);
            document.documentElement.scrollTop = document.body.scrollTop = osTop + ispeed;
            if (osTop === 0) {
                clearInterval(timer);
            }
        }, 30);
    }

    // 移入
    public mouseenter() {
        this.isDiv = false;
    }

    // 移出
    public mouseleave() {
        this.isDiv = true;
    }

    private async init() {
        try {
            // 这里的 FieldValue 值，根据总后台管理，写死
            const pageParams: any = {};
            pageParams.Expressionable = JSON.stringify([{ FieldName: 'CategoryId', FieldValue: 58, ConditionalType: searchConditionalTypeEnum['等于'] }]);
            // pageParams.Expressionable = JSON.stringify([{ FieldName: 'CategoryId', FieldValue: 41, ConditionalType: searchConditionalTypeEnum['等于'] }]);
            const { DataList } = await ArticleGetList(pageParams);
            if (DataList && DataList.length) {
                this.list = DataList;
            }
        } catch (error) {
            console.warn(error);
        }
    }

    // 查询
    public get queryList() {
        return (item) => {
            const results = this.list.find((e) => e.ArticleSubTitle === item);
            return results || {};
        };
    }

    public jump(item) {
        if (item.Link) {
            window.open(item.Link);
        }
    }

}
